import React from 'react';
import { Img, getImage, featuredImg } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import * as styles from '../assets/styles/postPage.module.sass';
import * as baseStyles from '../assets/styles/base.module.sass';

import {
	FacebookShareButton,
	FacebookIcon,
	EmailShareButton,
	EmailIcon,
	LinkedinShareButton,
	LinkedinIcon,
	RedditShareButton,
	RedditIcon,
	TelegramShareButton,
	TelegramIcon,
	TwitterShareButton,
	TwitterIcon,
	VKShareButton,
	VKIcon,
	WhatsappShareButton,
	WhatsappIcon,
	WorkplaceShareButton,
	WorkplaceIcon
} from "react-share";

const PostPage = ({ data }) => {
	const { html } = data?.markdownRemark;
	const { title, category, tagline, date } =
		data?.markdownRemark.frontmatter;
		let href = '';
		if (typeof window !== 'undefined') {
		  href = window.location.href;
		}
	return (
		<main className={styles.details}>
			<div className={baseStyles.container}>
				<div className={styles.container}>
					<div className={styles.badge}>
						<h3>{category}</h3> • <span>{date}</span>
					</div>
					<h1 className={styles.title}>{title}</h1>
					<p className={styles.tagline}>{tagline}</p>
					{/* <div className={styles.heroImage}>
						<Img image={heroImage} alt='' />
					</div> */}
					<br />
					<hr />
					<div
						className={'mode__content ' + styles.content}
						dangerouslySetInnerHTML={{ __html: html }}
					/> <br />
					<p>Share:</p>
					<FacebookShareButton url={href}>
						<FacebookIcon size={32} round={true} />
					</FacebookShareButton> &nbsp;

					<EmailShareButton url={href}>
						<EmailIcon size={32} round={true} />
					</EmailShareButton> &nbsp;

					<LinkedinShareButton url={href}>
						<LinkedinIcon size={32} round={true} />
					</LinkedinShareButton> &nbsp;

					<RedditShareButton url={href}>
						<RedditIcon size={32} round={true} />
					</RedditShareButton> &nbsp;

					<TelegramShareButton url={href}>
						<TelegramIcon size={32} round={true} />
					</TelegramShareButton> &nbsp;

					<TwitterShareButton url={href}>
						<TwitterIcon size={32} round={true} />
					</TwitterShareButton> &nbsp;

					<VKShareButton url={href}>
						<VKIcon size={32} round={true} />
					</VKShareButton> &nbsp;

					<WhatsappShareButton url={href}>
						<WhatsappIcon size={32} round={true} />
					</WhatsappShareButton> &nbsp;

					<WorkplaceShareButton url={href}>
						<WorkplaceIcon size={32} round={true} />
					</WorkplaceShareButton> &nbsp;

				</div>
			</div>
		</main>
	);
};

export default PostPage;

export const Head = ({
	data: {
		markdownRemark: { frontmatter },
	},
	location: { pathname },
}) => {
	const { title: postTitle, tagline: description } = frontmatter;
	const title = `${postTitle} | Shodipo Ayomide`;

	return (
		<>
			<title>{title}</title>
			<meta name='description' content={description} />
			<meta property='og:locale' content='en_US' />
			<meta property='og:type' content='website' />
			<meta
				property='og:url'
				content={`https://shodipoayomide.com${pathname}`}
			/>
			<meta property='og:logo' content='/favicon.png' size='32x32' />

			{/* Twitter */}
			<meta name='twitter:title' content={title} />
			<meta name='twitter:description' content={description} />

			{/* Open Graph */}
			<meta property='og:title' content={title} key='ogtitle' />
			<meta property='og:description' content={description} key='ogdesc' />
		</>
	);
};

export const query = graphql`
	query PostPage($slug: String) {
		markdownRemark(frontmatter: { slug: { eq: $slug } }) {
			html
			id
			frontmatter {
				category
				title
				tagline
				date
			}
		}
	}
`;
